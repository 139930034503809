<template>
  <div class="footer-block container-wrap">
    <h2>Nos autres<br>produits</h2>
    <div class="footer-product-wrap flex flex-col">
      <div class="footer-product-item flex w-full">
        <div class="footer-product-img w-1/2">
          <img class="footer_img" src="../assets/img/footer_img-1.svg" alt="" draggable="false">
        </div>
        <div class="footer-product-text w-1/2">
          <div class="footer_item-arrow absolute"></div>
          <h4>Crême de jour hydratante</h4>
          <span>19,90 €</span>
        </div>
      </div>
      <div class="footer-product-item flex w-full">
        <div class="footer-product-img w-1/2">
          <img class="footer_img" src="../assets/img/footer_img-2.svg" alt="" draggable="false">
        </div>
        <div class="footer-product-text w-1/2 relative">
          <div class="footer_item-arrow absolute"></div>
          <h4>Gommage Exfoliant</h4>
          <span>22,90 €</span>
        </div>
      </div>
      <div class="footer-product-item flex w-full">
        <div class="footer-product-img w-1/2">
          <img class="footer_img" src="../assets/img/footer_img_3.png" alt="" draggable="false">
        </div>
        <div class="footer-product-text w-1/2">
          <div class="footer_item-arrow absolute"></div>
          <h4>Masque Régénérant</h4>
          <span>29,50 €</span>
        </div>
      </div>
    </div>
    <div class="footer-logo-wrap relative flex flex-col items-center">
      <h3>Demetera</h3>
      <div class="button px-12">
        Voir le site
      </div>
    </div>
    <div class="footer-arrow-block">
      <div id="footer-arrow" class="footer-arrow-wrap flex justify-between bottom-0 w-full z-10 fixed" :class="{ 'opacity': isScrolled }">
        <div class="follow_us mx-5 flex justify-center items-center">Follow us !</div>
        <div class="footer-arrow-img-wrap flex justify-center items-center" @click="scrollToTop">
          <img class="arrow_img" src="../assets/img/footer_arrow.svg" alt="" draggable="false">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-block",
  data () {
    return {
      isScrolled: null,
      scrolled: null,
    }
  },
  created () {
    if (process.browser) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed () {
    if (process.browser){
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  watch : {
    scrolled: function (val) {
      if (val < 80) {
        this.isScrolled = false;
      }
      if (val >= 80) {
        this.isScrolled = true;
      }
    }
  },
  methods: {
    handleScroll () {
      this.scrolled = window.scrollY;
      // console.log(this.scrolled)
    },
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  }
}
</script>

<style scoped>
.footer-arrow-wrap  {
  opacity: 0;
  transition: all .5s;
  pointer-events: none;
}

.footer-arrow-wrap.opacity {
  opacity: 1;
  pointer-events: auto;
}

.footer-arrow-block {
  min-height: 98px;
}

.follow_us {
  color: #FFFFFF;
  width: 120px;
  font-size: 15px;
  font-family: Jost;
  background: url("../assets/img/follow_img.svg") no-repeat center;
  background-size: cover;
  text-transform: uppercase;
}
.footer-product-text h4 {
  max-width: 75%;
}
.footer_item-arrow {
  right: 35px;
  transform: rotate(315deg);
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  border-bottom: 1px solid #592C26;
  border-right: 1px solid #592C26;
  opacity: 0.3;
}
.footer-product-item {
  position: relative;
  height: 180px;
}
.footer-logo-wrap h3 {
  margin-bottom: 0;
}
.footer-product-item:before {
  position: absolute;
  content: '';
  width: calc(100% - 80px);
  height: 1px;
  background-color: #F5F5F5;
  bottom: 0;
  left: 40px;
}


.footer-product-img {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.footer-product-img img {
  max-height: 90%;
  max-width: 50%;
  object-fit: contain;
}

.footer-product-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: center;
  padding-right: 30px;
}

.footer-product-text span {
  color: #592C26;
  font-family: Jost-Light;
  font-size: calc(10px + 1.1vw);
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.6;
}

.footer-arrow-img-wrap {
  height: 58px;
  width: 58px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);
  border-radius: 50%;
  margin: 20px;
}

.footer-logo-wrap {
  background-color: rgba(255, 187, 149, 0.1);
  padding: 35px 0;
}

.footer-logo_img-wrap {
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-block .button {
  color: #FF6259;
  border: 1px solid #FCDDC9;
  font-family: Jost-Medium;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 60px;
  border-radius: 30px;
  outline: none;
  background-color: #FFEBE1;
  transition: all .5s;
  margin-top: 35px;
}

@media (max-width: 640px) {
  .footer-product-item {
    height: 150px;
  }

  .footer-product-img {
    padding-top: 35px;
  }

  .footer-logo-wrap {
    padding: 30px 0;
  }

  .footer-block .button {
    height: 50px;
    margin-top: 30px;
    font-size: 18px;
  }
}

@media (max-width: 390px) {
  .footer-product-text span {
    font-size: 14px;
  }

  .footer-product-item:before {
    width: calc(100% - 60px);
    left: 30px;
  }
}
</style>