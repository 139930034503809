<template>
  <div class="reviews container-wrap padding_wrap">
    <div class="reviews_title_block relative flex flex-col items-center">
      <h3 class="small mb-5">Elles l'ont testé</h3>
    </div>
    <div class="reviews_wrap w-full">
      <div class="reviews_item">
        <img class="reviews-img" src="../assets/img/insta-1.webp" alt="" draggable="false">
      </div>
      <div class="reviews_item">
        <img class="reviews-img" src="../assets/img/insta-2.webp" alt="" draggable="false">
      </div>
      <div class="reviews_item">
        <img class="reviews-img" src="../assets/img/insta-3.webp" alt="" draggable="false">
      </div>
      <div class="reviews_item">
        <img class="reviews-img" src="../assets/img/insta-4.webp" alt="" draggable="false">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reviews"
}
</script>

<style scoped>
.reviews_item {
  margin-bottom: 30px;
}

.reviews_wrap {
  padding-bottom: 30px;
}

@media (min-width: 640px) {
  .reviews_item {
    margin-bottom: 40px;
  }

  .reviews_wrap {
    padding-bottom: 40px;
  }
}

</style>