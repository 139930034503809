<template>
  <div>
    <transition name="fade">
      <div class="success-message padding_wrap fixed w-full" v-if="isSubmit">
        <div class="popup w-full z-10 flex flex-col justify-center items-center">
          <span class="mb-5">Merci ! </span>
          <h3 class="text-title mb-8">Vous allez recevoir par e-mail<br> votre code de réduction</h3>
          <div class="button_wrap flex justify-center mb-5">
            <div class="button px-14" @click="popup_close">
              OK
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="form container-wrap padding_wrap">
      <div class="form_title_block relative flex flex-col items-center mb-16">
        <h3 class="small mb-5">Questionnaire</h3>
        <p class="text-center">Bénéficiez de <span class="font-bold">-10%</span> sur votre prochain achat Demetera et de conseils personnalisés en répondant à notre questionnaire</p>
      </div>
      <form class="form mb-10" @submit.prevent="formAction" ref="contactsForm">
        <transition :name="transitionName">
          <div class="form_slide_wrap" v-show="slide === 1">
            <p class="mb-8 font-bold">1. Quel est votre type de peau ?</p>
            <div class="radio-wrapper">
              <div class="form-item form_radio px-5 py-4" :class="{ active: picked === 'Sèche'}">
                <input type="radio" class="radio" name="type" id="radio-1" value='Sèche' v-model="picked" required>
                <label for="radio-1" class="font-medium pl-12">Sèche</label>
              </div>
              <div class="form-item form_radio p-5" :class="{ active: picked === 'Grasse'}">
                <input type="radio" class="radio" name="type" id="radio-2" value='Grasse' v-model="picked">
                <label for="radio-2" class="font-medium pl-12">Grasse</label>
              </div>
              <div class="form-item form_radio p-5" :class="{ active: picked === 'Mixte'}">
                <input type="radio" class="radio" name="type" id="radio-3" value='Mixte' v-model="picked">
                <label for="radio-3" class="font-medium pl-12">Mixte</label>
              </div>
            </div>
            <div class="button_wrap flex justify-center p-5">
              <div class="button px-8" :class="{ disabled: !picked}" @click="next(2, picked)">
                Suivant
              </div>
  <!--            <button class="button px-8" :disabled="!picked" @click="next_slide(2)">-->
  <!--              Suivant-->
  <!--            </button>-->
            </div>
          </div>
        </transition>
        <transition :name="transitionName">
          <div class="form_slide_wrap" v-show="slide === 2">
            <p class="mb-8 font-bold">2. Quel âge avez vous ? </p>
            <div class="radio-wrapper">
              <div class="form-item form_radio px-5 py-4" :class="{ active: year === '12-18'}">
                <input type="radio" class="radio" name="year" id="radio-4" value='12-18' v-model="year"  required>
                <label for="radio-4" class="font-medium pl-12">12-18 ans</label>
              </div>
              <div class="form-item form_radio p-5" :class="{ active: year === '18-28'}">
                <input type="radio" class="radio" name="year" id="radio-5" value='18-28' v-model="year">
                <label for="radio-5" class="font-medium pl-12">18-28 anse</label>
              </div>
              <div class="form-item form_radio p-5" :class="{ active: year === '28-40'}">
                <input type="radio" class="radio" name="year" id="radio-6" value='28-40' v-model="year">
                <label for="radio-6" class="font-medium pl-12">28-40 ans</label>
              </div>
              <div class="form-item form_radio p-5" :class="{ active: year === '40-50'}">
                <input type="radio" class="radio" name="year" id="radio-7" value='40-50' v-model="year">
                <label for="radio-7" class="font-medium pl-12">40-50 ans</label>
              </div>
              <div class="form-item form_radio p-5" :class="{ active: year === '+50'}">
                <input type="radio" class="radio" name="year" id="radio-8" value='+50' v-model="year">
                <label for="radio-8" class="font-medium pl-12">+ de 50 ans</label>
              </div>
            </div>
            <div class="button_wrap flex justify-between py-5">
              <div class="arrow arrow_left"  @click="prev()">
                <img class="arrow_img" src="../assets/img/form_arrow_left.svg" alt="" draggable="false">
              </div>
              <div class="button px-8" :class="{ disabled: !year}" @click="next(3 ,year)">
                Suivant
              </div>
            </div>
          </div>
        </transition>
        <transition :name="transitionName">
          <div class="form_slide_wrap" v-show="slide === 3">
            <p class="mb-8 font-bold">3. Nous avons besoin de votre adresse mail pour vous envoyer nos conseils personalisés :</p>
            <div class="email-form-wrap flex flex-col">
              <input  class="email-input p-5 md:p-7.5" required v-model.trim="email" type="email" id="email" name="email" :class="{ 'no-empty': email }" >
              <label for="email">Adresse mail</label>
            </div>
            <div class="button_wrap flex justify-between py-5">
              <div class="arrow arrow_left" @click="prev">
                <img class="arrow_img" src="../assets/img/form_arrow_left.svg" alt="" draggable="false">
              </div>
              <button class="button px-8" :disabled="!email" type="submit">
                Envoyer
              </button>
            </div>
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "form-block",
  data() {
    return {
      email: null,
      picked: null,
      year: null,
      isSubmit: false,
      slide: 1,
      prev_slide: 0,
      transitionName: null
    }
  },
  watch: {
    slide(val) {
      this.prev_slide = --val
    }
  },
  methods: {
    next(id, value) {
      console.log(888, value)
      if (value) {
        this.slide = id
        this.transitionName = 'fadeRight'
      }
    },
    prev() {
      this.slide = this.prev_slide
      this.transitionName = 'fadeLeft'
    },
    formAction() {
      this.isSubmit = true
      // let form = this.$refs.contactsForm
      document.querySelector('body').classList.add('scroll_none')
      console.log(this.email, this.picked, this.year )
      // setTimeout(() => {
      //     this.isSubmit = false
      //     document.querySelector('body').classList.remove('scroll_none')
      //     this.email = null
      //     this.picked = null
      //     this.year = null
      //     form.reset()
      // }, 1800);
    },
    popup_close() {
      let form = this.$refs.contactsForm
      this.isSubmit = false
      document.querySelector('body').classList.remove('scroll_none')
      this.email = null
      this.picked = null
      this.year = null
      this.slide = 1
      form.reset()
    }
  }
}
</script>

<style scoped>
.form-item.form_radio {
  width: 100%;
  height: 70px;
  background-color: rgba(255, 187, 149, 0.15);
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all .5s;
}

input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;
  outline: none;
  -webkit-tap-highlight-color: transparent!important;
}

/*input[type="radio"]:focus {*/
/*  background: transparent;*/
/*  box-shadow: none;*/
/*  outline: 0!important;*/
/*  outline: none!important;*/
/*  -webkit-tap-highlight-color: transparent!important;*/
/*}*/


label {
  color: #592C26;
  font-family: Jost;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1.3;
  display: flex;
  align-items: center;
  outline: none;
}

input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
  display: inline-block;
  position: relative;
  /*padding-left: 20px;*/
  /*line-height: 20px;*/
  cursor: pointer;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border: 2px solid #FF6259;
  border-radius: 50%;
  background-color: transparent;
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  left: calc((24px/2) - 5px);
  top: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF6259;
}

input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="radio"]:checked + label:after {
  opacity: 1;
}


.form-item.form_radio.active  {
  background-color: rgba(255, 187, 149, 0.5);
}

.form .button,
.popup .button {
  color: #FFFFFF;
  font-family: Jost-Medium;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 60px;
  border-radius: 30px;
  outline: none;
  background-color: rgba(0, 0, 0, 1);
  transition: all .5s;
}

.form .button.disabled,
.form button.button:disabled {
  background-color: rgba(0, 0, 0, 0.1);
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 50%;
}

.email-form-wrap input {
  order: 2;
  height: 70px;
  margin-bottom: 15px;
  font-size: 22px;
  width: 100%;
  color: rgba(89, 44, 38, 1);
  border: 1px solid #F6AE94;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
}

.email-form-wrap label {
  position: relative;
  color: rgba(89, 44, 38, 0.5);
  font-size: 22px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  font-family: Jost;
  letter-spacing: 0;
  line-height: 1.3;
  order: 1;
  top: 48px;
  left: 20px;
  -webkit-transition: 0.2s cubic-bezier(0.21, 0.65, 0.4, 0.96);
  transition: 0.2s cubic-bezier(0.21, 0.65, 0.4, 0.96);
}

.email-form-wrap input:focus + label,
.email-form-wrap .no-empty + label {
  -webkit-transform: translateY(-16px);
  transform: translateY(-16px);
  font-size: 18px;
}

.email-form-wrap input:focus,
.email-form-wrap .no-empty {
  padding-bottom: 5px;
  padding-top: 30px;
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fadeLeft-enter-active {
  transition: all .5s;
}

.fadeLeft-enter-to, .fadeLeft-leave  {
  opacity: 1;
  -webkit-transform: translateX(0);
}
.fadeLeft-enter, .fadeLeft-leave-to {
  opacity: 0;
  -webkit-transform: translateX(-20px);
}

.fadeRight-enter-active {
  transition: all .5s;
}

.fadeRight-enter-to, .fadeRight-leave  {
  opacity: 1;
  -webkit-transform: translateX(0);
}
.fadeRight-enter, .fadeRight-leave-to {
  opacity: 0;
  -webkit-transform: translateX(20px);
}

.popup h3 {
  text-transform: none;
  color: #592C26;
  font-family: Jost;
  font-size: calc(18px + 1.6vw);
}

.success-message {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  padding-top: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(85, 92, 108, 0.4);
}

.popup {
  padding: 40px;
  /*height: 40%;*/
  background: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
  max-width: 640px;
  margin: 0 auto;
}

.popup span {
  color: #592C26;
  font-family: Jost;
  font-size: calc(40px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
}


@media (max-width: 640px) {
  .email-form-wrap input,
  .form-item.form_radio {
    height: 60px;
  }

  label,
  .form .button,
  .email-form-wrap label,
  .email-form-wrap input{
    font-size: 18px;
  }

  .email-form-wrap input:focus + label,
  .email-form-wrap .no-empty + label {
    font-size: 12px;
    -webkit-transform: translateY(-14px);
    transform: translateY(-14px);
  }

  .email-form-wrap label {
    top: 40px;
    left: 20px;
  }

  .email-form-wrap input:focus,
  .email-form-wrap .no-empty {
    padding-bottom: 5px;
    padding-top: 25px;
  }

  .form .button,
  .popup .button {
    height: 50px;
  }

  .success-message {
    padding-top: 30px;
  }

  .popup h3 {
    font-size: 20px;
  }

  .popup span {
    font-size: 50px;
  }

  .popup {
    padding: 30px;
  }
}

@media (max-height: 450px) {
  .popup h3 {
    font-size: calc(8px + 1.6vw);
    margin-bottom: 20px;
    padding: 25px 0;
    height: 100px;
  }

  .success-message {
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  .popup {
    padding: 30px;
    max-height: 90%;
    margin: auto;
  }

  .popup span {
    margin-bottom: 0;
  }

  .popup h3:before {
    position: absolute;
    content: '';
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
}






</style>