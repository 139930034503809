<template>
  <div class="application container-wrap">
    <div class="application_title_block padding_wrap relative flex flex-col items-center mb-16">
      <h3 class="small mb-5">APPLIQUER SA CRÈME<br>DE JOUR ?</h3>
      <div class="application_title-text flex flex-col items-start m-w-97">
        <p class="mb-5">La crème de jour étant un essentiel dans votre routine beauté, vous l’appliquez méticuleusement tous les matins. Mais comment maximiser l’efficacité de ce geste incontournable ?</p>
        <p class="font-bold">Ici, nous vous expliquons tout !</p>
      </div>
    </div>
    <div class="application_img_text_block mb-16">
      <div class="application_img_text_item padding_wrap pt-16 pb-24 relative">
        <p>Pour commencer, l’application de votre soin doit être effectué sur peau parfaitement démaquillée, séchée et débarrassée des impuretés.</p>
        <div class="application-img-wrap border_img my-8 md:my-16">
          <img class="application-img" src="../assets/img/application_img_1.png" alt="" draggable="false">
        </div>
      </div>
      <div class="application_img_text_item padding_wrap pt-16 pb-24 -mt-8 relative">
        <p>A partir du bout des doigts, massez délicatement la peau en partant du centre du visage et en allant vers l’extérieur.</p>
        <div class="application-wrap border_img my-8 md:my-16">
          <img class="application-img" src="../assets/img/application_img_2.png" alt="" draggable="false">
        </div>
      </div>
      <div class="application_img_text_item padding_wrap pt-16 pb-24 -mt-8 relative">
        <p>Toujours dans la même direction, effectuez des petits mouvements circulaires pour faciliter la pénétration de votre crème de jour et stimuler les cellules du derme.</p>
        <div class="application-img-wrap border_img my-8 md:my-16">
          <img class="application-img" src="../assets/img/application_img_3.png" alt="" draggable="false">
        </div>
      </div>
      <div class="application_img_text_item padding_wrap pt-16 pb-8 -mt-8 relative">
        <p>Pensez à ne pas négliger le menton et le cou, des zones tout aussi concernées par votre crème de jour.
          Hydrater ces zones permet d’entretenir l’élasticité de la peau, qui a tendance à se relâcher avec l’âge.</p>
        <div class="application-img-wrap border_img border my-8 md:my-16">
          <img class="application-img" src="../assets/img/application_img_4.png" alt="" draggable="false">
        </div>
      </div>
    </div>
<!--    <div class="application_ingredients_block padding_wrap flex flex-col items-center mb-16">-->
<!--      <h3 class="small my-8">Ingédients</h3>-->
<!--      <div class="application_ingredients_wrap w-full">-->
<!--        <div class="application_ingredients_item aloe mb-2.5 md:mb-5">-->
<!--          <h5>Aloe vera</h5>-->
<!--        </div>-->
<!--        <div class="application_ingredients_item citron mb-2.5 md:mb-5">-->
<!--          <h5>Citron</h5>-->
<!--        </div>-->
<!--        <div class="application_ingredients_item pamplemouse mb-2.5 md:mb-5">-->
<!--          <h5>Pamplemouse</h5>-->
<!--        </div>-->
<!--        <div class="application_ingredients_item romarin mb-8">-->
<!--          <h5>Romarin</h5>-->
<!--        </div>-->
<!--        <div class="application_text_bottom flex justify-start">-->
<!--          <span class="text-left">Aqua (Water), Dicaprylyl Ether, Glycerin, Zinc PCA, Caprylic/Capric Triglyceride,-->
<!--          Helianthus Annuus (Sunflower) Seed Wax, Polyglyceryl-3 Methylglucose Distearate, Myristyl Myristate, 1,2-Hexanediol,-->
<!--          Phyllostachys Bambusoides Extract, Silica, Sclerotium Gum, C14-22 Alcohols, Caesalpinia Spinosa Gum,-->
<!--          Rosmarinus Officinalis (Rosemary) Leaf Oil, C12-20 Alkyl Glucoside, O-Cymen-5-Ol, Limonene, Tocopherol</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "application"
}
</script>

<style scoped>
.m-w-97 {
  max-width: 97%;
}
.application_img_text_item {
  border-radius: 35px 35px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.application_img_text_item:before {
  position: absolute;
  height: 70px;
  width: 70px;
  background-color: #FFFFFF;
  color: #592C26;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 35px);
  top: -35px;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  font-family: Jost;
  border-radius: 50%;
  box-shadow: 10px 20px 20px 0 rgba(97,11,11,0.18);
}

.application_img_text_item:nth-child(1):before {
  content:'1';
}

.application_img_text_item:nth-child(2):before {
  content:'2';
}

.application_img_text_item:nth-child(3):before {
  content:'3';
}

.application_img_text_item:nth-child(4):before {
  content:'4';
}

.application_img_text_item:nth-child(odd) {
  background-color: #FFBB95;
}

.application_img_text_item:nth-child(even) {
  background-color: #FCDDC9;
}

.application-img {
  width: 100%;
  object-fit: cover;
  max-width: 600px;
}

/*.application_ingredients_item {*/
/*  display: flex;*/
/*  justify-content: flex-start;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*  height: calc(75px + 5.6vw);*/
/*  padding-left: 40px;*/
/*}*/
/*.application_ingredients_item.aloe {*/
/*  background: url("../assets/img/application_bttom_img_1.jpg") no-repeat center;*/
/*  background-size: cover;*/
/*}*/
/*.application_ingredients_item.citron {*/
/*  background: url("../assets/img/application_bttom_img_2.jpg") no-repeat center;*/
/*  background-size: cover;*/
/*}*/
/*.application_ingredients_item.pamplemouse {*/
/*  background: url("../assets/img/application_bttom_img_3.jpg") no-repeat center;*/
/*  background-size: cover;*/
/*}*/
/*.application_ingredients_item.romarin {*/
/*  background: url("../assets/img/application_bttom_img_4.jpg") no-repeat center;*/
/*  background-size: cover;*/
/*}*/

/*.application_text_bottom span {*/
/*  color: #592C26;*/
/*  font-family: Jost;*/
/*  font-size: calc(3px + 1.6vw);*/
/*  letter-spacing: 0;*/
/*  line-height: 1.3;*/
/*}*/

/*@media (max-width: 640px) {*/
/*  .application_text_bottom span {*/
/*    font-size: 14px;*/
/*  }*/

/*  .application_ingredients_item {*/
/*    height: 110px;*/
/*    padding-left: 30px;*/
/*  }*/
/*}*/

@media (max-width: 640px) {
  .m-w-97 {
    max-width: 95%;
  }
}

</style>