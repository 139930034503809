<template>
  <div class="pour-la-planete container-wrap">
    <div class="pour-la-planete_title_block padding_wrap relative flex flex-col items-center mb-12 sm:mb-14">
      <h3 class="small mb-5">COMMENT RECYCLER VOS PRODUITS DEMETERA ?</h3>
      <div class="pour-la-planete_title-text flex flex-col items-start">
        <p class="font-bold mb-5">Nous lançons une opération zéro déchet !</p>
        <p>Nous avons mis a disposition en boutique des poubelles destinées aux emballages cosmétiques pour qu'ils soient recyclés et pour
          5 emballages vides rapportés en magasin, <span class="font-bold">un bon d’achat de 5€</span> est offert !</p>
      </div>
    </div>
    <div class="pour-la-planete_img_wrap mb-14">
      <img class="pour-la-planete-img mb-8 sm:mb-10" src="../assets/img/pour_la_planete_img.jpg" alt="" draggable="false">
      <p class="font-bold padding_wrap pb-1">Rapportez vos emballages vides demetera pour leur donner une seconde vie !</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "pour-la-planete"
}
</script>

<style scoped>
.pour-la-planete_img_wrap p {
  font-family: Jost-SemiBold;
}
</style>