<template>
  <div class="header-block">
    <header class="header-wrap flex flex-col">
      <div class="header_title relative w-full container-wrap padding_wrap flex flex-col justify-between">
        <span class="background_text absolute">Pure</span>
        <div class="title_wrap flex flex-col justify-center items-center relative">
          <img class="header_img" src="../assets/img/header_img_3x.webp" alt="" draggable="false">
          <h1>Crême de jour <br> hydratante</h1>
          <span>PURE</span>
        </div>
      </div>
      <nav class="header_nav container-wrap padding_wrap">
        <ul class="header_nav_wrapper w-full flex justify-between">
          <li class="header_nav_item" :class="{ active: page === 1}" @click="nextPage(1)">
            <a href="javascript:void(0);" class="header_nav_item_link">La marque</a>
          </li>
          <li class="header_nav_item" :class="{ active: page === 2}" @click="nextPage(2)">
            <a href="javascript:void(0);" class="header_nav_item_link">Application</a>
          </li>
          <li class="header_nav_item" :class="{ active: page === 3}" @click="nextPage(3)">
            <a href="javascript:void(0);" class="header_nav_item_link">Bon d'achat</a>
          </li>
        </ul>
        <ul class="header_nav_wrapper w-full flex justify-between">
          <li class="header_nav_item" :class="{ active: page === 4}" @click="nextPage(4)">
            <a href="javascript:void(0);" class="header_nav_item_link">Ingrédients</a>
          </li>
          <li class="header_nav_item" :class="{ active: page === 5}" @click="nextPage(5)">
            <a href="javascript:void(0);" class="header_nav_item_link">Pour la planète</a>
          </li>
          <li class="header_nav_item" :class="{ active: page === 6}" @click="nextPage(6)">
            <a href="javascript:void(0);" class="header_nav_item_link">Elles l'ont testé</a>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: 'header-block',
  data() {
    return {
      page: 1,
      pagesHashes: {
        1: '#la-marque',
        2:  '#application',
        3:  '#contact-us',
        4:  '#ingredients',
        5:  '#pour-la-planete',
        6:  '#reviews',
      }
    }
  },
  watch: {
    page: function (index) {
      window.location.hash = this.pagesHashes[index]
    }
  },
  mounted() {
    if (window.location.hash) {
      Object.values(this.pagesHashes).forEach((hash, index) => {
        if (window.location.hash === hash) {
          this.page = index + 1
          this.nextPage(index + 1)
        }
      })
    } else {
      //set hash of first default page
      window.location.hash = this.pagesHashes[1]
    }

  },
  methods: {
    nextPage(id) {
      this.page = id
      // console.log(888, id)
      this.$emit('nextPage', id)
    }
  }
}
</script>

<style scoped>


.header_title {
  overflow: hidden;
  position: relative;
  padding-top: 12vw;
  padding-bottom: 12vw;
  background: url("../assets/img/header_background_3x.webp") no-repeat center;
  background-size: cover;
}

.header_title:before {
  position: absolute;
  content: '';
  top:0;
  right: 0;
  width: 100px;
  height: 100px;
  background: url("../assets/img/swipe_arrow_dowm.svg") no-repeat center;
  background-size: cover;
}

.header_title:after {
  position: absolute;
  content: '';
  top:0;
  right: 0;
  width: 100px;
  height: 100px;
  background: url("../assets/img/swipe-up.svg") no-repeat center;
  background-size: cover;
  animation: swipe 2s ease-in infinite, opacity 2s ease-in infinite;
  /*animation-duration: 2s;*/
  /*animation-name: swipe;*/
}

@keyframes swipe {
  from {
    transform: rotate(-50deg);
  }

  to {
    transform: rotate(20deg);
  }
}

@keyframes opacity {
  from {
    opacity: 1;
  }
  50% {opacity: 1;}
  75% {opacity: 1}
  85% {opacity: 0}
  to {
    opacity: 0
  }
}


/*@keyframes swipe {*/
/*  from {transform: rotate(-50deg);}*/
/*  50% {transform: rotate(0deg);}*/
/*  to {transform: rotate(20deg);}*/
/*}*/


.header_title span.background_text {
  text-transform: uppercase;
  opacity: 0.28;
  color: #FFFFFF;
  font-size: calc(250px + 12vw);
  letter-spacing: normal;
  line-height: 1.3;
  bottom: calc(12vw + 50px);
  left: 0;
  width: 100%;
  font-family: 'Jost';
}
.header_img {
  object-fit: contain;
  /*width: 96%;*/
  padding-left: 12px;
}

.title_wrap span {
  font-size: calc(14px + 1.5vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  opacity: 0.75;
  color: #592C26;
  font-family: 'Jost-MediumItalic';
}

.header_nav {
  border-radius: 45px 45px 0 0;
  background-color: #FFFFFF;
  margin-top: calc((-110px - 3vw)/2);
  z-index: 2;
  /*transform: translateY(-50%);*/
  /*margin-bottom: calc(-20px - 4.3vw);*/
  padding-top: 40px;
  padding-bottom: 40px;
}

.header_nav_wrapper:first-child {
  margin-bottom: 15px;
}
.header_nav_item {
  flex: 0 1 calc((100% - 30px) / 3);
}

.header_nav_item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(30px + 3vw);
  color: #000000;
  font-size: calc(10px + 0.8vw);
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
  border-radius: calc(30px + 2vw);
  box-sizing: border-box;
  /*border: 1px solid #000000;*/
  font-family: 'Jost-Medium';
  transition: all 0.2s ease-out;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.header_nav_item.active {
  border: 1px solid #000000;
  transition: all 0.2s ease-out;
}

@media (max-width: 1024px) {
  .container-wrap {
    max-width: 1024px;
  }

  .header_title span.background_text {
    font-size: calc(100px + 25vw);
    bottom: calc(12vw + 50px);
  }

  /*.header_nav {*/
  /*  margin-bottom: calc(-25px - 4.3vw);*/
  /*}*/
}

@media (max-width: 768px) {
  .container-wrap {
    max-width: 768px;
  }
}

@media (max-width: 640px) {
  .container-wrap {
    max-width: 640px;
  }
  .header_title span.background_text {
    font-size: calc(25px + 34vw);
    bottom: calc(10vw + 120px);
  }

  .header_title {
    padding-top: 106px;
    padding-bottom: 106px;
  }

  .header_nav {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: calc(-98px/2);
    margin-top: calc((-90px - 3vw)/2);
  }

  /*.header_nav {*/
  /*  margin-bottom: -65px;*/
  /*}*/
}

@media (max-width: 390px) {
  .header_title {
    padding-top: 86px;
    padding-bottom: 85px;
  }


  .header_title span.background_text {
    font-size: 150px;
    line-height: 217px;
    bottom: 120px;
  }

  .title_wrap span {
    font-size: 16px;
    line-height: 23px;
  }

  .header_nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .header_nav_item {
    height: 38px;
    border-radius: 22.5px;
    font-size: calc(10px + 0.6vw);
  }

  .header_nav {
    margin-top: calc(-98px/2);
  }

}

@media (max-width: 360px) {
  .header_title span.background_text {
    font-size: 130px;
    bottom: 110px;
  }
}

@media (max-width: 320px) {
  .header_title span.background_text {
    font-size: 120px;
  }

  .header_nav_item {
    font-size: calc(10px + 0.2vw);
  }

  .header_nav_wrapper:first-child {
    margin-bottom: 10px;
  }
  .header_nav_item {
    flex: 0 1 calc((100% - 20px) / 3);
  }
}
</style>
