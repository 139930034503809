<template>
  <div class="la-marque container-wrap">
    <div class="la-marque-top_block padding_wrap relative flex flex-col items-center">
      <h3 class="mb-5 md:mb-10">Demetera</h3>
      <p>C’est lorsque deux jeunes femmes passionnées de 28 ans se sont lancées dans une nouvelle aventure que
        DEMETERA est née en 2019. Après plusieurs années d’expérience au sein de grandes marques de cosmétiques renommées,
        Mathilde Violon et Mila Walk ont saisi l’opportunité de créer une marque de soins entièrement nouvelle.</p>
      <div class="la-marque-img-wrap border_img my-8 md:my-16">
        <img class="la-marque-img" src="../assets/img/la-marque-img.jpg" alt="" draggable="false">
      </div>
    </div>
    <div class="la-marque-center_block pt-32 relative">
      <img class="la-marque-img-bg-top absolute" src="../assets/img/Rectangle.svg" alt="" draggable="false">
      <div class="la-marque-text text-left padding_wrap pb-5">
        <p class="mb-8 md:mb-16">Elles ont rassemblé une équipe d’experts en soins cosmétiques dans le but de révolutionner le secteur de la beauté.
          Le marché de la beauté se positionne avec des promesses douteuses et des formules incertaines,
          Mathilde et Mila ont donc choisi de surprendre et satisfaire au mieux les consommateurs.</p>
        <span class="comment white">Nous nous sommes concentrées sur ce qui fait d’un soin un produit d’excellence. Alors nous avons misé sur la qualité des ingrédients.</span>
      </div>
      <div class="la-marque-center-img-wrap">
        <img class="la-marque-img mx-auto" src="../assets/img/la-marque-img_center.jpg" alt="" draggable="false">
      </div>
      <div class="la-marque-text text-left padding_wrap pb-10 md:pb-20">
        <p>Après plusieurs mois de recherche et développement, la première gamme de soin PURE a été lancée.</p>
      </div>
      <img class="la-marque-img-bg-bottom absolute" src="../assets/img/Rectangle_bottom.svg" alt="" draggable="false">
    </div>
    <div class="la-marque-bottom_block my-12 lg:my-28 xl:my-40">
      <div class="la-marque-bottom-text-img-wrap pt-16 pb-5 padding_wrap">
        <p class="la-marque-bottom_text-img">Le concept de la gamme est de proposer une liste d’ingrédients de qualité, naturels et la plus courte possible.
          Dans cette première gamme, deux produits essentiels : La crème de jour hydratante et le gommage doux.</p>
      </div>
      <div class="la-marque-bottom-comment padding_wrap py-12">
        <span class="comment orange">Nous nous sommes concentrées sur ce qui fait d’un soin un produit d’excellence. Alors nous avons misé sur la qualité des ingrédients.</span>
      </div>
      <p class="padding_wrap py-5">Les deux jeunes femmes espèrent proposer une vraie alternative aux produits de grande consommation.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "la-marque"
}
</script>

<style scoped>
.la-marque {
}
.la-marque-top_block {
  z-index: 2;
}

.la-marque-center_block {
  z-index: 1;
  /*margin-top: -50px;*/
  margin-top: -125px;
}


/*.la-marque-center_block:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  background: url("../assets/img/Rectangle.svg") no-repeat center;*/
/*  background-size: cover;*/
/*  bottom: 100%;*/
/*  left: 0;*/
/*}*/

.la-marque-img-bg-top {
  width: 100%;
  bottom: 99%;
  left: 0;
  object-fit: cover;
}

.la-marque-img-bg-bottom {
  width: 100%;
  top: 99%;
  left: 0;
  object-fit: cover;
}

.la-marque-center_block {
  background-color: #FFBB95;
}

.la-marque-bottom-text-img-wrap {
  display: flex;
  align-items: center;
  min-height: 360px;
  background: url("../assets/img/la_marque_bottom_img.png") no-repeat right;
  background-size: contain;
}

.la-marque-bottom-comment {
  background: url("../assets/img/DemiLogo.svg") no-repeat left;
  background-size: contain;
}

.la-marque-bottom_text-img {
  max-width: 60%;
}
</style>