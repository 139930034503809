<template>
  <div id="app" class="relative">
    <Header @nextPage='nextPage'></Header>
    <transition name="fadeFast">
      <Page1 v-if="page===1"></Page1>
      <Page2 v-if="page===2"></Page2>
      <Page3 v-if="page===3"></Page3>
      <Page4 v-if="page===4"></Page4>
      <Page5 v-if="page===5"></Page5>
      <Page6 v-if="page===6"></Page6>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/header-block.vue'
import Footer from './components/footer-block.vue'
import Page1 from './components/la-marque.vue'
import Page2 from './components/application.vue'
import Page3 from './components/form-block.vue'
import Page4 from './components/ingredients'
import Page5 from './components/pour-la-planete'
import Page6 from './components/reviews'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6
  },
  data() {
    return {
      page: 1,
    }
  },
  methods: {
    nextPage (id) {
      // console.log(id)
      this.page  = id
    }
  }
}
</script>

<style>
.fadeFast-enter-active {
  transition: all 1s;
}
.fadeFast-enter-to, .fadeFast-leave  {
  opacity: 1;
}
.fadeFast-enter, .fadeFast-leave-to {
  opacity: 0;
}

body {
  box-sizing: border-box;
  /*overflow-x: hidden;*/
  scroll-behavior: smooth;
  margin: 0;
}
body.scroll_none {
  overflow: hidden;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  /*max-width: 640px;*/
  margin: 0 auto;
  min-height: 100vh;
  background-color: #FFFFFF;
}

.container-wrap {
  max-width: 1280px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.padding_wrap {
  padding-left: 40px;
  padding-right: 40px;
}

p {
  color: #592C26;
  font-family: Jost;
  font-size: calc(10px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
}

h1 {
  position: relative;
  color: #592C26;
  font-size: calc(20px + 2.5vw);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 8px;
  margin-bottom: 8px;
  letter-spacing: 0.87px;
  line-height: 1.25;
  font-family: 'Jost-Medium';
}

h1:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 45%;
  height: calc(1px + 0.2vw);
  width: 10%;
  opacity: 0.15;
  transform: rotate(180deg);
  border-radius: 2.5px;
  background-color: #592C26;
}

h2 {
  color: #592C26;
  font-family: Jost-Light;
  font-size: calc(20px + 1.7vw);
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  position: relative;
  color: #592C26;
  font-family: Jost-Light;
  font-size: calc(25px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
  /*font-size: 32px;*/
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  padding: 50px 0;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h3:before {
  position: absolute;
  content: '';
  width: 150px;
  height: 150px;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  background: url("./assets/img/Rosace.svg") no-repeat center;
  background-size: contain;
}

h3.small {
  font-size: calc(10px + 1.6vw);
  font-family: Jost;

}

h4 {
  color: #592C26;
  font-family: Jost-Medium;
  font-size: calc(12px + 1.6vw);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3;
}

h5 {
  color: #FFFFFF;
  font-family: Jost-SemiBold;
  font-size: calc(14px + 1.6vw);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.3px;
}

.border_img {
  box-sizing: border-box;
  border: 10px solid #FFFFFF;
  box-shadow: 10px 20px 20px 0 rgba(97,11,11,0.18);
}

.comment {
  position: relative;
  font-family: Jost-LightItalic;
  font-size: calc(25px + 1.6vw);
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
}

.comment.white {
  color: #FFFFFF;
}

.comment.orange {
  color: #592C26;
}

.comment:before {
  content: '';
  width: 25px;
  height: 25px;
  position: absolute;
  background-size: cover;
  top:0;
  left: 0;
}

.comment:after {
  width: 25px;
  height: 25px;
  content: '';
  position: absolute;
  background-size: cover;
  bottom:0;
  right: 0;
}

.comment.white:before {
  background: url("./assets/img/comment_left.svg") no-repeat center;
}

.comment.white:after{
  background: url("./assets/img/comment_right_white.svg") no-repeat center;
}

.comment.orange:before {
  background: url("./assets/img/comment_left_orange.svg") no-repeat center;
}

.comment.orange:after{
  background: url("./assets/img/comment_right_orange.svg") no-repeat center;
}

@media (max-width: 1024px) {
  .container-wrap {
    max-width: 1024px;
  }
}

@media (max-width: 768px) {
  .container-wrap {
    max-width: 768px;
  }
}

@media (max-width: 640px) {
  .container-wrap {
    max-width: 640px;
  }

  .padding_wrap {
    padding-left: 30px;
    padding-right: 30px;
  }

  p {
    font-size: 20px;
  }

  .comment {
    font-size: 30px;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 18px;
  }

  h3 {
    font-size: 32px;
    padding: 35px 0;
    height: 112px;
  }

  h3:before {
    width: 112px;
    height: 112px;
    top: calc(50% - 56px);
    left: calc(50% - 56px);
  }

  h3.small {
    font-size: 20px;
  }

  h5 {
    font-size: 24px;
  }
}

@media (max-width: 390px) {
  .container-wrap {
    max-width: 390px;
  }

  h1 {
    font-size: 24px;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  h1:before {
    left: calc(50% - 15px);
    height: 2px;
    width: 30px;
  }

}


</style>
