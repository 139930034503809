<template>
  <div class="application_ingredients_block padding_wrap flex flex-col items-center mb-16">
    <h3 class="small mb-8">Ingédients</h3>
    <div class="application_ingredients_wrap w-full">
      <div class="application_ingredients_item aloe mb-2.5 md:mb-5">
        <h5>Aloe vera</h5>
      </div>
      <div class="application_ingredients_item citron mb-2.5 md:mb-5">
        <h5>Citron</h5>
      </div>
      <div class="application_ingredients_item pamplemouse mb-2.5 md:mb-5">
        <h5>Pamplemouse</h5>
      </div>
      <div class="application_ingredients_item romarin mb-8">
        <h5>Romarin</h5>
      </div>
      <div class="application_text_bottom flex justify-start">
          <span class="text-left">Aqua (Water), Dicaprylyl Ether, Glycerin, Zinc PCA, Caprylic/Capric Triglyceride,
          Helianthus Annuus (Sunflower) Seed Wax, Polyglyceryl-3 Methylglucose Distearate, Myristyl Myristate, 1,2-Hexanediol,
          Phyllostachys Bambusoides Extract, Silica, Sclerotium Gum, C14-22 Alcohols, Caesalpinia Spinosa Gum,
          Rosmarinus Officinalis (Rosemary) Leaf Oil, C12-20 Alkyl Glucoside, O-Cymen-5-Ol, Limonene, Tocopherol</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ingredients"
}
</script>

<style scoped>
.application_ingredients_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(75px + 5.6vw);
  padding-left: 40px;
}

.application_ingredients_item.aloe {
  background: url("../assets/img/application_bttom_img_1-1280.jpg") no-repeat center;
  background-size: cover;
}
.application_ingredients_item.citron {
  background: url("../assets/img/application_bttom_img_2-1280.jpg") no-repeat center;
  background-size: cover;
}
.application_ingredients_item.pamplemouse {
  background: url("../assets/img/application_bttom_img_3.jpg") no-repeat center;
  background-size: cover;
}
.application_ingredients_item.romarin {
  background: url("../assets/img/application_bttom_img_4-1280.jpg") no-repeat center;
  background-size: cover;
}

.application_text_bottom span {
  color: #592C26;
  font-family: Jost;
  font-size: calc(3px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
}

@media (max-width: 640px) {
  .application_text_bottom span {
    font-size: 14px;
  }

  .application_ingredients_item {
    height: 110px;
    padding-left: 30px;
  }
}
</style>